'use client';

import { type InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useLocale } from 'next-intl';
import { memo, Suspense, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { type InterestsFormModalCmsContent } from '@module/mdrt-org/account-settings/components/interests-form/interests-form-modal-cms-content-type';
import { mapUrlLocaleToOptimizelyCaseSensitiveLocale } from '@shared/utils';

import {
  type LearnCarouselBlockFragment,
  type Locales,
  type PageMainContentFragment,
} from '../../utils/data/graphql/_generated/gql-generated';

import { getCarouselsAction, type GetCarouselsActionResponse } from './actions';
import {
  LearnContentCarousel,
  type LearnContentCarouselProps,
  LearnContentCarouselSkeleton,
} from './components';
import { CAROUSELS_PER_PAGE, LearnDataTag } from './constants';
import { type LearnGenericTextsCmsContent } from './graphql/get-learn-generic-texts-cms-content';
import { LearnCarouselContentPageSkeleton } from './learn-carousel-content-page-skeleton';
import { getPageCarouselBlockGuids } from './utils/get-page-carousel-block-guids';
import { InterestsData } from '@module/mdrt-org/shared/utils/data/graphql/utils';

const LearnContentCarouselWrapper = memo(
  (props: LearnContentCarouselProps & { carouselGuid: string }) => {
    return (
      <Suspense fallback={<LearnContentCarouselSkeleton />}>
        <LearnContentCarousel {...props} />
      </Suspense>
    );
  },
  (previous, next) => previous.carouselGuid === next.carouselGuid
);

const getQueryKeyOfGetCarouselsAction = (pageBlocks: PageMainContentFragment[]) => {
  return [LearnDataTag.GET_CAROUSELS_ACTION, pageBlocks];
};

export type LearnCarouselBlockClientProps = {
  cmsContent: LearnGenericTextsCmsContent;
  currentBlock: LearnCarouselBlockFragment;
  customerInterests: number[];
  initialGetCarouselsResponse: GetCarouselsActionResponse;
  pageBlocks: PageMainContentFragment[];
  preferenceTopics: InterestsData;
  preferencesCmsContent: InterestsFormModalCmsContent;
};

const LearnCarouselBlockClientInner = ({
  cmsContent,
  currentBlock,
  customerInterests,
  initialGetCarouselsResponse,
  pageBlocks,
  preferenceTopics,
  preferencesCmsContent,
}: LearnCarouselBlockClientProps) => {
  const locale = useLocale() as Locales;

  const pageCarouselBlockGuids = getPageCarouselBlockGuids(pageBlocks);
  const { data, fetchNextPage, isError, isFetchingNextPage } = useInfiniteQuery({
    enabled: false,
    getNextPageParam: (lastPage: GetCarouselsActionResponse) => lastPage.nextCategoriesToSkip,
    initialPageParam: initialGetCarouselsResponse.nextCategoriesToSkip,
    queryFn: ({ pageParam }) =>
      getCarouselsAction({
        Categories: pageCarouselBlockGuids.map((guid, index) => ({
          Identifier: guid ?? '',
          Order: index,
        })),
        CategoriesToSkip: pageParam,
        Language: mapUrlLocaleToOptimizelyCaseSensitiveLocale(locale),
        Limit: CAROUSELS_PER_PAGE,
      }),
    queryKey: getQueryKeyOfGetCarouselsAction(pageBlocks),
  });
  const carouselsPages = [initialGetCarouselsResponse, ...(data?.pages ?? [])];
  const carouselsForCurrentBlock = carouselsPages.flatMap(
    (carouselsPage) =>
      carouselsPage.guidToCarousels?.[currentBlock?.ContentLink?.GuidValue ?? ''] ?? []
  );

  const loadedCarouselGuids = carouselsPages.flatMap((carouselsPage) =>
    Object.keys(carouselsPage.guidToCarousels)
  );
  const loadedPageCarouselGuids = pageCarouselBlockGuids.filter((pageCarouselGuid) =>
    loadedCarouselGuids.includes(pageCarouselGuid)
  );
  const isCurrentCarouselLastLoaded =
    loadedPageCarouselGuids.reverse().indexOf(currentBlock?.ContentLink?.GuidValue ?? '') === 0;
  const finishedFetching =
    isError ||
    (carouselsPages.length > 0 &&
      Object.entries(carouselsPages[carouselsPages.length - 1]?.guidToCarousels ?? {}).length ===
        0);
  const isFirstCarousel = pageCarouselBlockGuids[0] === currentBlock.ContentLink?.GuidValue;
  const showLoaderAtTheEnd =
    (isCurrentCarouselLastLoaded || (isFirstCarousel && carouselsPages.length === 0)) &&
    !finishedFetching;

  const { inView, ref: inViewRef } = useInView({
    rootMargin: '200%',
    threshold: 0,
  });
  useEffect(() => {
    if (inView && isCurrentCarouselLastLoaded && !finishedFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, finishedFetching, inView, isCurrentCarouselLastLoaded, isFetchingNextPage]);

  return (
    <>
      {carouselsForCurrentBlock.length > 0 &&
        carouselsForCurrentBlock.map((carousel) => (
          <LearnContentCarouselWrapper
            cards={carousel.cards}
            carouselGuid={carousel.guid}
            carouselType={carousel.type}
            cmsContent={cmsContent}
            customerInterests={customerInterests}
            key={carousel.guid}
            preferenceTopics={preferenceTopics}
            preferencesCmsContent={preferencesCmsContent}
            selectedSourcesIds={carousel.selectedSourcesIds}
            showMagazineIssueFilter={carousel.showMagazineIssueFilter}
            showSourcesFilter={carousel.showSourcesFilter}
            sourcesFilterAllSelectionLabel={carousel.sourcesFilterAllSelectionLabel}
            title={carousel.title}
          />
        ))}
      <div ref={inViewRef} />
      {showLoaderAtTheEnd && <LearnCarouselContentPageSkeleton />}
    </>
  );
};

export const LearnCarouselBlockClient = (props: LearnCarouselBlockClientProps) => {
  // hydration error bypass
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  const carouselsForCurrentBlock =
    props.initialGetCarouselsResponse?.guidToCarousels?.[
      props.currentBlock?.ContentLink?.GuidValue ?? ''
    ] ?? [];

  // query is saved after navigation between pages.
  // firstRender is true in this case though, so scroll messes up
  const queryClient = useQueryClient();
  const carouselsActionData = queryClient.getQueryData(
    getQueryKeyOfGetCarouselsAction(props.pageBlocks)
  ) as InfiniteData<GetCarouselsActionResponse, unknown>;
  const areSomeCarouselsCached = carouselsActionData?.pages?.length > 0;

  if (!areSomeCarouselsCached && firstRender && carouselsForCurrentBlock.length > 0) {
    return <LearnContentCarouselSkeleton />;
  }

  if (!areSomeCarouselsCached && firstRender) {
    return null;
  }

  return <LearnCarouselBlockClientInner {...props} />;
};
